import React from "react";
import Card from "../../Base/Card";

const Detail = ({ entity }) => {
  return (
    <Card
      className="mt-4"
      title={<h5>Descripción</h5>}
      body={
        <>
          <h6>Sobre nosotros:</h6>
          <p>{entity.about_us}</p>
          <h6>Dirección:</h6>
          <p>{entity.address}</p>
        </>
      }
    ></Card>
  );
};

export default Detail;
