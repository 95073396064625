import React from "react";
import "./footer.scss";
import Fonselp from "./fonselp.webp";

const Footer = () => {
  return (
    <footer className="footer d-flex flex-column align-items-center justify-content-around flex-wrap">
      <h4 className="container_title">Una iniciativa de:</h4>
      <div className="container footer__logos  flex-wrap borders">
        <a
          href="https://winguweb.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="footer__wingu"
            preserveAspectRatio="xMidYMid meet"
            data-bbox="0 0 355.66 81.071"
            viewBox="0 0 355.66 81.071"
            xmlns="http://www.w3.org/2000/svg"
            data-type="color"
            role="img"
            aria-labelledby="comp-k8swty881-svgtitle"
          >
            <title id="comp-k8swty881-svgtitle"></title>
            <g>
              <g>
                <path
                  fill="#2681ff"
                  d="M214.91 22.46v41.65h-10.34V22.46h10.34z"
                  data-color="1"
                ></path>
                <path
                  d="M246.31 21.66a16.06 16.06 0 00-7.12 1.51 12.89 12.89 0 00-4.77 3.74c-.09-.17-1.1-2.11-2.47-4.44h-7.55v41.65h10.3V39.48a7.64 7.64 0 012.49-6.05 8.88 8.88 0 016.07-2.19A7.88 7.88 0 01249 33.4a8.09 8.09 0 012.23 6.07v24.65h10.34v-27.3q0-6.88-4.24-11a15.08 15.08 0 00-11-4.14"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M345.33 22.55v24.81a7.58 7.58 0 01-2.49 6 9 9 0 01-6.15 2.17 7.92 7.92 0 01-5.73-2.15 8 8 0 01-2.23-6.06V22.55h-10.3V50q0 6.88 4.22 11a15.06 15.06 0 0011 4.12 16.4 16.4 0 007.22-1.51 12.87 12.87 0 004.87-3.9s1.05 2.11 2.4 4.4h7.52V22.55z"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M209.72 3.88a5.73 5.73 0 00-4.26 1.67 5.83 5.83 0 00-1.65 4.28 5.65 5.65 0 005.91 5.91A5.84 5.84 0 00214 14.1a5.73 5.73 0 001.67-4.26A5.79 5.79 0 00214 5.53a5.84 5.84 0 00-4.28-1.65"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M289.32 55.8c-7.33 0-10.46-6.11-10.46-12.45s3.14-12.46 10.46-12.46 10.45 6.11 10.45 12.46-3.12 12.45-10.45 12.45M300 27.11c-2.67-3.46-7.17-5.46-12.75-5.46a17 17 0 00-13.46 6.07q-5.25 6.08-5.25 15.57t5.25 15.6A16.9 16.9 0 00287.28 65c7.34 0 11.37-3.47 12.55-5.18v2.78a7.69 7.69 0 01-1.89 5.23 10.73 10.73 0 01-4.73 3.17 19.17 19.17 0 01-6.21 1 34.42 34.42 0 01-7.44-.84l-.61-.14L275 79a34.53 34.53 0 004.09 1.16 39.62 39.62 0 008.59.91 30.54 30.54 0 008.59-1.17 23.91 23.91 0 007.12-3.36 15.52 15.52 0 004.93-5.73 17.32 17.32 0 001.81-8V22.46h-7.53c-1.6 2.82-2.6 4.65-2.6 4.65"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  fill="#2681ff"
                  d="M180.19 53.64l-9.31-31.05h-6.96l-9.35 31.05-8.47-31.05h-10.62l12.24 41.51h12.79l6.89-23.33 6.89 23.33h12.79l12.15-41.51h-10.62l-8.42 31.05z"
                  data-color="1"
                ></path>
                <path
                  d="M67.52 33.14C55 26 41.34 22 29.1 22a46.55 46.55 0 00-25.78 7.36 119.39 119.39 0 004.86 13.69c5.16-4.6 12.44-7.15 20.92-7.15 9.86 0 21.06 3.3 31.53 9.29a125.26 125.26 0 0126.45 20.32c.66.68 1.27 1.35 1.87 2a111.77 111.77 0 007.89-11.89 138.11 138.11 0 00-29.32-22.48z"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M29.1 55.21c-4.49 0-7.77 1.21-9 3.33a5.47 5.47 0 00-.58 3.19A8.48 8.48 0 0021.21 66a108.33 108.33 0 0011 12.74s6.65-8 19-16.53a.13.13 0 000-.22c-7.52-4.32-15.57-6.8-22.06-6.8"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M58.37 49.15c-9.8-5.6-20.19-8.69-29.27-8.69-7.93 0-14.59 2.51-18.94 7A94 94 0 0015 56.6c.17-.44 5-5.95 14.09-5.95 7 0 15.7 2.48 24.21 7.34.74.42 6.12 3.75 7.83 5a96.74 96.74 0 0118 15.76c2.5-2.48 4.8-5 6.92-7.59Q85 69.91 83.8 68.67a120.43 120.43 0 00-25.43-19.52z"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M12.77 7.56A129.05 129.05 0 000 13a81.8 81.8 0 002.09 11.74 51.46 51.46 0 0127-7.27c13 0 27.47 4.16 40.68 11.72a143.81 143.81 0 0129.35 22.28 118.7 118.7 0 006.68-15.37 162.85 162.85 0 00-28.21-20.56A110.09 110.09 0 0058.88 7a69 69 0 00-46.11.56z"
                  fill="#2681ff"
                  data-color="1"
                ></path>
                <path
                  d="M55.64 0c-1.25 0-2.49 0-3.72.06a.07.07 0 00-.07.07s0 .06.07.07a113 113 0 0127.93 11.38 168 168 0 0127.51 19.8A93.82 93.82 0 00111.28 13 124.87 124.87 0 0055.64 0z"
                  fill="#2681ff"
                  data-color="1"
                ></path>
              </g>
            </g>
          </svg>
        </a>
        <a
          href="https://www.civic.house/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="footer__civic_house"
            preserveAspectRatio="xMidYMid meet"
            data-bbox="0 -0.005 284.515 159.155"
            viewBox="0 -0.005 284.515 159.155"
            xmlns="http://www.w3.org/2000/svg"
            data-type="color"
            role="img"
            aria-labelledby="comp-k8swty7v-svgtitle"
          >
            <title id="comp-k8swty7v-svgtitle"></title>
            <g>
              <path
                d="M245.67 80.61l-47.89-15.57a.45.45 0 00-.58.51 17.77 17.77 0 008 11.77h.09a18.58 18.58 0 003.81 1.75l25.3 8.24c5.37 1.75 9-1.38 11.46-6.15a.45.45 0 00-.2-.6h-.06"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M206.89 63.78l.11-50.81a.45.45 0 00-.66-.4 17.78 17.78 0 00-8.74 11.25v.16a18.62 18.62 0 00-.5 4.17v27c0 5.65 4.06 8.11 9.37 9a.45.45 0 00.52-.44"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M211.09 21.25l47.8-15.71a.45.45 0 00.17-.75 17.77 17.77 0 00-13.4-4.75h-.17a18.45 18.45 0 00-4.11.84l-25.25 8.31c-5.37 1.76-6.42 6.4-5.62 11.72a.45.45 0 00.51.38h.07"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M252.3 11.82L282 53.04a.45.45 0 00.77-.07 17.77 17.77 0 00.49-14.24v-.16a18.61 18.61 0 00-2-3.67l-15.81-21.93c-3.3-4.58-8-4.19-12.88-1.81a.45.45 0 00-.2.6v.06"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M273.73 48.87l-29.55 40.82a.45.45 0 00.3.71 17.77 17.77 0 0013.67-4l.13-.11a18.6 18.6 0 002.84-3.09l15.64-21.57c3.31-4.58 1.44-9-2.34-12.79a.45.45 0 00-.64 0v.05"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M145.55 127.9c-3.8-1.37-6.34-2.57-7.55-3.58a4.77 4.77 0 01-1.64-3.91 5.24 5.24 0 011.57-4.12 6.42 6.42 0 014.47-1.47 17.64 17.64 0 016 1.15 26.48 26.48 0 016.26 3.37l.45.32a.45.45 0 00.7-.27l1.53-7.17a.45.45 0 00-.16-.44l-.17-.14a22.39 22.39 0 00-6.72-3.66 24.4 24.4 0 00-8.12-1.39c-4.85 0-8.75 1.39-11.59 4.12a14.57 14.57 0 00-4.3 11c0 4 1.23 7.11 3.65 9.38s5.94 4.15 10.71 5.84a27.63 27.63 0 017.16 3.52 4.85 4.85 0 011.73 4 5.7 5.7 0 01-6.36 6.23 22 22 0 01-7.09-1.24 26.22 26.22 0 01-7-3.66l-.43-.31a.45.45 0 00-.7.25l-1.95 7.43a.45.45 0 00.18.49l.21.14a31.1 31.1 0 007.74 3.74 26.82 26.82 0 008.29 1.38c5.39 0 9.64-1.47 12.62-4.37a15.23 15.23 0 004.52-11.4 12.83 12.83 0 00-3.41-9.33c-2.26-2.33-5.8-4.2-10.71-5.89"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M25 72.18c-.23 3.88-.93 6.61-2.05 8.1s-2.79 2.08-5.31 2.08c-2.74 0-4.73-.69-5.76-2s-1.73-4-1.73-7.46V55.7c0-3.53.6-6.17 1.74-7.64s3-2 5.71-2c2.51 0 4.25.71 5.33 2.16s1.83 4.41 2.07 8.39v.43a.45.45 0 00.45.42h9.13a.45.45 0 00.45-.45v-.46c-.22-6.19-1.86-11.2-4.62-14.1s-7.1-4.57-12.52-4.57c-6 0-10.56 1.53-13.44 4.54S0 49.83 0 55.24v18.1c0 5.38 1.52 9.63 4.49 12.72s7.36 4.48 13.3 4.48c5.46 0 9.7-1.51 12.58-4.48s4.45-7.78 4.63-13.79v-.23a.45.45 0 00-.41-.49h-9.1a.45.45 0 00-.49.42z"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M63.16 81.97H58a.45.45 0 01-.45-.45V47.37a.45.45 0 01.45-.45h5.2a.45.45 0 00.45-.45v-7.35a.45.45 0 00-.45-.45H41.69a.45.45 0 00-.45.45v7.35a.45.45 0 00.45.45h5.2a.45.45 0 01.45.45v34.12a.45.45 0 01-.45.45h-5.2a.45.45 0 00-.45.45v7.35a.45.45 0 00.45.45h21.47a.45.45 0 00.45-.45v-7.35a.45.45 0 00-.45-.45"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M106.42 38.68h-8.79a.45.45 0 00-.44.35l-7.74 36a.45.45 0 01-.88 0l-7.68-36a.45.45 0 00-.44-.36H71.1a.45.45 0 00-.44.55l11.89 50.63a.45.45 0 00.44.35h11.48a.45.45 0 00.44-.35l12-50.62a.45.45 0 00-.34-.54h-.1"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M135.45 81.97h-5.32a.45.45 0 01-.45-.45V47.37a.45.45 0 01.45-.45h5.32a.45.45 0 00.45-.45v-7.35a.45.45 0 00-.45-.45h-21.56a.45.45 0 00-.45.45v7.35a.45.45 0 00.45.45h5.32a.45.45 0 01.45.45v34.12a.45.45 0 01-.45.45h-5.32a.45.45 0 00-.45.45v7.35a.45.45 0 00.45.45h21.58a.45.45 0 00.45-.45v-7.35a.45.45 0 00-.45-.45"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M173.26 86.04c2.79-2.87 4.45-7.78 4.67-13.79v-.28a.45.45 0 00-.41-.49h-9.16a.45.45 0 00-.45.41v.21c-.24 3.88-.93 6.61-2 8.1s-2.79 2.08-5.31 2.08c-2.73 0-4.72-.69-5.76-2s-1.73-4-1.73-7.46V55.67c0-3.53.6-6.17 1.73-7.64s3-2 5.7-2c2.51 0 4.25.71 5.32 2.16s1.83 4.41 2.07 8.39v.39a.45.45 0 00.45.42h9.13a.45.45 0 00.45-.45v-.46c-.22-6.19-1.86-11.2-4.62-14.1s-7.1-4.57-12.52-4.57c-6 0-10.55 1.53-13.43 4.54s-4.45 7.41-4.45 12.82v18.17c0 5.38 1.51 9.66 4.47 12.72s7.36 4.48 13.3 4.48c5.46 0 9.69-1.51 12.58-4.48"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M22.89 127.58H10.62a.45.45 0 01-.45-.45V107.7a.45.45 0 00-.45-.45H.45a.45.45 0 00-.45.45v50.62a.45.45 0 00.45.45h9.27a.45.45 0 00.45-.45v-21.59a.45.45 0 01.45-.45h12.27a.45.45 0 01.45.45v21.59a.45.45 0 00.45.45h9.27a.45.45 0 00.45-.45V107.7a.45.45 0 00-.45-.45h-9.27a.45.45 0 00-.45.45v19.44a.45.45 0 01-.45.45"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M108.34 141.59c0 3.55-.47 5.9-1.43 7.18s-2.65 1.92-5 1.92-4.3-.66-5.25-1.92-1.66-3.89-1.66-7.18v-33.9a.45.45 0 00-.45-.45h-9.12a.45.45 0 00-.45.45v34c0 5.73 1.43 10.13 4.25 13.09s7 4.36 12.67 4.36 9.7-1.47 12.46-4.36 4.06-7.23 4.06-13.09v-34a.45.45 0 00-.45-.45h-9.18a.45.45 0 00-.45.45z"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M197 115.04v-7.35a.45.45 0 00-.45-.45h-27.8a.45.45 0 00-.45.45v50.62a.45.45 0 00.45.45h27.8a.45.45 0 00.45-.45v-7.34a.45.45 0 00-.45-.45h-17.73a.45.45 0 01-.45-.45v-13.34a.45.45 0 01.45-.45h15.37a.45.45 0 00.45-.45v-7.79a.45.45 0 00-.45-.45h-15.37a.45.45 0 01-.45-.45v-11.17a.45.45 0 01.45-.45h17.74a.45.45 0 00.45-.45"
                fill="#ffffff"
                data-color="1"
              ></path>
              <path
                d="M66 141.16c0 3.82-.46 6.34-1.41 7.7s-2.57 2-5.05 2-4.28-.68-5.23-2-1.63-4.16-1.63-7.7v-17c0-3.47.57-6.1 1.65-7.6.94-1.31 2.74-2 5.2-2s4.09.65 5 2 1.44 3.86 1.44 7.6zm5.8-30.19c-2.87-3-7-4.55-12.32-4.55s-9.57 1.49-12.48 4.55-4.37 7.38-4.37 12.84v17.83c0 5.44 1.46 9.78 4.4 12.89s7.07 4.62 12.47 4.62 9.35-1.55 12.24-4.62 4.2-7.33 4.2-12.89v-17.88c0-5.59-1.37-9.9-4.16-12.83"
                fill="#ffffff"
                data-color="1"
              ></path>
            </g>
          </svg>
        </a>
        <a
          href="https://fonselp.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer__fonselp" src={Fonselp} alt="" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
