import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/api";

export const actionTypes = {
  GetEntitiesMapStart: "[ENTITIES MAP] GET START",
  GetEntitiesMapComplete: "[ENTITIES MAP] GET COMPLETE",
  GetEntitiesMapError: "[ENTITIES MAP] GET ERROR",
};

const initialState = {
  loading: false,
  entitiesMap: null,
  error: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetEntitiesMapStart: {
      return { ...state, loading: true, entitiesMap: null };
    }
    case actionTypes.GetEntitiesMapComplete: {
      const entitiesMap = action.results.data;
      return { ...state, loading: false, entitiesMap };
    }
    case actionTypes.GetEntitiesMapError: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        entitiesMap: null,
        error,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getEntitiesMap: (page) => ({ type: actionTypes.GetEntitiesMapStart, page }),
};
// Watchers

export function* getEntitiesMapState({ page }) {
  try {
    const setPage = page ? `&pages=${page}` : "";
    const entityTypes = "3,4,5,6,7,8,10,11,12,13,14";
    // const entityTypes = "1,2,3,4,5,6,7,8,10,9";
    const results = yield call(
      apiCall,
      `entity?filter[isOwnerNetworks]=0&filter[inTypes]=${entityTypes}${setPage}&sort=-updated_at`,
      null,
      "GET"
    );
    yield put({ type: actionTypes.GetEntitiesMapComplete, results });
  } catch (error) {
    yield put({ type: actionTypes.GetEntitiesMapError, error });
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetEntitiesMapStart, getEntitiesMapState);
}
