import React from "react";
import { withGoogleMap, GoogleMap } from "react-google-maps";

const { google } = window;

const Map = withGoogleMap(({ markets, center }) => {
  const defaultCenter = center
    ? center
    : { lat: -31.4200832, lng: -64.18877609999998 };

  const defaultZoom = center ? 7 : 3;
  return (
    <GoogleMap defaultZoom={defaultZoom} defaultCenter={defaultCenter}>
      {markets}
    </GoogleMap>
  );
});

export default Map;
