import React from "react";
import Entity from "../Entity";

const Cards = ({ entities }) => {
  const showEntity = () => {
    return (
      entities &&
      entities.data.map((entity) => (
        <Entity key={`entity-${entity.id}`} id={entity.id} entity={entity} />
      ))
    );
  };

  return <div className="card-columns">{showEntity()}</div>;
};

export default Cards;
