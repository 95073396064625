import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import * as modal from "./ducks/modal.duck";
import * as entities from "./ducks/entity/getEntities.duck";
import * as entitiesMap from "./ducks/entity/getEntitiesMap.duck";
import * as entity from "./ducks/entity/getEntity.duck ";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    modal: modal.reducer,
    entities: entities.reducer,
    entitiesMap: entitiesMap.reducer,
    entity: entity.reducer,
  });

export function* rootSaga() {
  yield all([entities.saga(), entity.saga(), entitiesMap.saga()]);
}
