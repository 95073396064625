import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../store/ducks/entity/getEntitiesMap.duck";
import { actions as modalActions } from "../../../store/ducks/modal.duck";
import { FaSearch } from "react-icons/fa";

import Card from "../../Base/Card";
import Map from "../../Base/Map";
import Marker from "../../Base/Marker";

const MapHome = ({ className, search }) => {
  const [switchFilter, setSwitchFilter] = useState(true);
  const { entitiesMap } = useSelector((state) => ({
    entitiesMap: state.entitiesMap.entitiesMap,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!entitiesMap) dispatch(actions.getEntitiesMap(1000));
  }, [dispatch, entitiesMap]);

  const getMarket =
    entitiesMap &&
    entitiesMap.data.map((entityMap) => <Marker entity={entityMap} />);

  const openModal = () => {
    dispatch(
      modalActions.modalShow({
        modalProps: {
          open: true,
          search: search,
        },
        modalType: "filter",
      })
    );
  };

  const toogleSwitchFilter = () => {
    setSwitchFilter(!switchFilter);
    search({ isRed: switchFilter });
  };

  return (
    <Card
      className={className}
      title={
        <div className="d-flex justify-content-between align-items-center  flex-wrap">
          <h6 className="m-0">Organizaciones validadas</h6>
          <div className="d-flex justify-content-between align-items-center  flex-wrap">
            <h6 className="mb-0 mr-1">Ver Organizaciones:</h6>
            <label className="m-0 mx-2">Miembros</label>
            <div className="form-group mb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  disabled=""
                  id="customSwitch2"
                  onClick={toogleSwitchFilter}
                />
                <label className="custom-control-label" for="customSwitch2">
                  Redes
                </label>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={openModal}
            >
              <FaSearch className="mr-3" />
              Buscar
            </button>
          </div>
        </div>
      }
      body={
        <Map
          containerElement={<div style={{ height: "250px" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          markets={getMarket}
        />
      }
    ></Card>
  );
};

export default MapHome;
