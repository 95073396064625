/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MapInfo from "../../Logic/MapInfo";
import Ods from "../../Logic/Ods";
import Network from "../../Logic/Networks";
import Detail from "../../Logic/Detail";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../store/ducks/entity/getEntity.duck ";
import { useParams } from "react-router";
import Spinner from "../../Base/Spinner";

const Info = () => {
  const { id } = useParams();
  const { entity, loading, error } = useSelector((state) => ({
    entity: state.entity.entity,
    loading: state.entity.loading,
    error: state.entity.error,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!entity || (entity && entity.id !== id))
      dispatch(actions.getEntity(id));
  }, [id]);

  if (loading) {
    return (
      <div className="d-flex align-items-center">
        <Spinner />
      </div>
    );
  }

  if (entity)
    return (
      <div>
        <MapInfo entity={entity} onlySelect></MapInfo>
        <Detail entity={entity}></Detail>
        <div className="row">
          <div className="col-md-6">
            <Network networks={entity.networks} id={id}></Network>
          </div>
          <div className="col-md-6">
            <Ods sdgs={entity.sdgs}></Ods>
          </div>
        </div>
      </div>
    );
  return null;
};

export default Info;
