import React, { useState } from "react";

import { Marker as MarkerNpm, InfoWindow } from "react-google-maps";
import { Link } from "react-router-dom";

const Marker = ({ entity, onlySelect }) => {
  const [open, setOpen] = useState(false);
  const onToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <MarkerNpm
      key={entity.id}
      position={{
        lat: parseFloat(entity.location_lat),
        lng: parseFloat(entity.location_lng),
      }}
      defaultTitle={entity.bussiness_name}
      onClick={onToggleOpen}
    >
      {open && (
        <InfoWindow onCloseClick={onToggleOpen}>
          <div id="content">
            <div id="siteNotice" />
            <h1
              id="firstHeading"
              className="firstHeading"
              style={{ fontSize: "1.2rem" }}
            >
              {entity.bussiness_name}
            </h1>

            <div id="bodyContent">
              <p>{entity.address}</p>
              {!onlySelect && (
                <p>
                  <Link to={`/info/${entity.id}`}>Ver mas</Link>
                </p>
              )}
            </div>
          </div>
        </InfoWindow>
      )}
    </MarkerNpm>
  );
};

export default Marker;
