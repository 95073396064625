import React from "react";
import NetworkLogo from "../../Base/NetworkLogo";

const Networks = ({ networks, id, className }) => {
  const getNetwoks = () =>
    networks.map((network) => (
      <NetworkLogo
        key={`OverlayTrigger-${network.id}-${id}`}
        network={network}
        box="100px"
      ></NetworkLogo>
    ));

  return (
    <div className={`card rounded mt-4 ${className}`}>
      <div className="card-header">
        <h5>Redes</h5>
      </div>
      <div className="card-body ">{getNetwoks()}</div>
    </div>
  );
};

export default Networks;
