import React from "react";
import { ods } from "./ods/icons-inverted/index";
import styled from "styled-components";
const Img = styled.img`
  height: 85px;
`;

const Ods = ({ sdgs, className }) => {
  const getSdgs = () => {
    return sdgs.map((sdg) => {
      return <Img src={ods[sdg.id - 1]}></Img>;
    });
  };
  return (
    <div className={`card rounded mt-4 ${className}`}>
      <div className="card-header">
        <h5>
          <a
            href="https://www.un.org/sustainabledevelopment/es"
            target="_blank"
            rel="noopener noreferrer"
          >
            Objetivos de desarrollo sostenible
          </a>
        </h5>
      </div>
      <div className="card-body text-center">
        {/* {ods.map((obj) => (
          <Img src={obj}></Img>
        ))} */}
        {getSdgs()}
      </div>
    </div>
  );
};

export default Ods;
