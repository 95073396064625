import React from "react";
import { Switch, Route } from "react-router-dom";

import Info from "../Info";
import Home from "../Home";
import ModalRoot from "../../Base/Modal/ModalRoot";

const Main = () => {
  return (
    <div className="container mt-4">
      <Switch>
        <Route exact path="/">
          <Home></Home>
        </Route>
        <Route path="/info/:id">
          <Info></Info>
        </Route>
      </Switch>
      <ModalRoot />
    </div>
  );
};

export default Main;
