import React from "react";
import Header from "./components/Layout/Header";
import Main from "./components/Layout/Main";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footer from "./components/Layout/Footer";
import "./App.scss";

function App() {
  return (
    <Router>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </Router>
  );
}

export default App;
