import React from "react";
import Card from "../../Base/Card";
import Map from "../../Base/Map";
import Marker from "../../Base/Marker";

const MapInfo = ({ entity, className }) => {
  const getMarket = entity && <Marker entity={entity} onlySelect />;

  const centerMap = {
    lat: parseInt(entity.location_lat),
    lng: parseInt(entity.location_lng),
  };

  return (
    <Card
      className={className}
      title={<h5>{entity.bussiness_name}</h5>}
      body={
        <Map
          containerElement={<div style={{ height: "250px" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          markets={getMarket}
          center={centerMap}
        />
      }
    ></Card>
  );
};

export default MapInfo;
