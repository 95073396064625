// actions
export const actionTypes = {
  modalShow: "[MODAL] SHOW",
  modalHide: "[MODAL] HIDDEN",
};

const initialState = {
  modalType: null,
  modalProps: {
    open: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.modalShow: {
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    }
    case actionTypes.modalHide: {
      return initialState;
    }
    default:
      return state;
  }
};

// Action Creators
export const actions = {
  modalShow: ({ modalType, modalProps }) => ({
    type: actionTypes.modalShow,
    modalType,
    modalProps,
  }),
  modalHide: () => ({ type: actionTypes.modalHide }),
};
