/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../store/ducks/entity/getEntities.duck";

import Cards from "../../Logic/Cards";
import MapHome from "../../Logic/MapHome";
import Spinner from "../../Base/Spinner";
import Pagination from "../../Base/Pagination";

const Home = () => {
  const [filter, setFilter] = useState({ isRed: false });
  const [meta, setMeta] = useState({});
  const { entities, loading, error } = useSelector((state) => ({
    entities: state.entities.entities,
    loading: state.entities.loading,
    error: state.entities.error,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!entities || filter) dispatch(actions.getEntities(filter));
  }, [filter]);

  useEffect(() => {
    if (entities) {
      setMeta(entities.meta);
    }
  }, [entities]);

  const search = (newfilter) => {
    if (newfilter) setFilter({ ...filter, ...newfilter });
  };

  return (
    <>
      <MapHome className="mb-4" search={search} />
      {loading ? (
        <div className="d-flex align-items-center">
          <Spinner />
        </div>
      ) : (
        <Cards entities={entities} />
      )}

      <Pagination meta={meta} action={actions.getEntities} />
    </>
  );
};

export default Home;
