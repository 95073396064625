import React from "react";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NerworkImg = styled.img`
  height: ${(props) => props.box || "50px"};
  width: ${(props) => props.box || "50px"};
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-right: 15px;
  margin-bottom: 15px;
`;

const NerworkLogo = ({ network, box }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{network.short_description}</Tooltip>}>
      <NerworkImg
        data-toggle="tooltip"
        data-placement="top"
        title={network.name}
        data-original-title="Tooltip on top"
        src={network.logo_url}
        alt={`logo de red ${network.name}`}
        className="profile-pic"
        box={box}
      />
    </OverlayTrigger>
  );
};

export default NerworkLogo;
