import React from "react";

const Card = ({ title, body, footer, className = "" }) => {
  return (
    <div className={"card rounded " + className}>
      {title && <div className="card-header">{title}</div>}
      {body && <div className="card-body">{body}</div>}
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};

export default Card;
