import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../../utils/api";

export const actionTypes = {
  GetEntityStart: "[ENTiTY] GET START",
  GetEntityComplete: "[ENTiTY] GET COMPLETE",
  GetEntityError: "[ENTiTY] GET ERROR",
};

const initialAuthState = {
  loading: false,
  entity: null,
  error: "",
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.GetEntityStart: {
      return { ...state, loading: true, entity: null };
    }
    case actionTypes.GetEntityComplete: {
      const entity = action.results.data.data[0];
      return { ...state, loading: false, entity };
    }
    case actionTypes.GetEntityError: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        entity: null,
        error,
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getEntity: (id) => ({ type: actionTypes.GetEntityStart, id }),
};
// Watchers

export function* getEntityState({ id }) {
  try {
    const results = yield call(
      apiCall,
      `entity/${id}?filter[isOwnerNetworks]=0&include=networks,sdgs`,
      null,
      "GET"
    );
    yield put({ type: actionTypes.GetEntityComplete, results });
  } catch (error) {
    yield put({ type: actionTypes.GetEntityError, error });
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetEntityStart, getEntityState);
}
